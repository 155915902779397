const comando = [
  {
    img: 'https://d2er8q8v25uk9z.cloudfront.net/giovanna_eNFVjKYbC3XT.webp',
    name: 'Giovanna',
    role: 'Vice-presidente',
  },
  {
    img: 'https://d2er8q8v25uk9z.cloudfront.net/alex_47qrhnHgQsGv.webp',
    name: 'Alex',
    role: 'Disciplina',
  },
  {
    img: 'https://d2er8q8v25uk9z.cloudfront.net/vital.webp',
    name: 'Pr. Vital',
    role: 'Brandeiro',
  },
  {
    img: 'https://d2er8q8v25uk9z.cloudfront.net/wesley.webp',
    name: 'Wesley',
    role: 'SGT de Armas',
  },
]

export default comando;